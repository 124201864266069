import {
  CHANNEL_TYPES,
  CONTEXTUAL_OPERATION_TYPE,
  ERROR_CODE,
  IActivity,
  IGetPresenceResult,
  IInteraction,
  INTERACTION_MULTIPARTY_STATES,
  INTERACTION_STATES,
  ISupportedChannel,
  LOG_LEVEL,
  SearchLayouts,
  SearchRecords,
  clickToAct,
  clickToDial,
  getLiveAgents,
  getPresence,
  logout,
  registerOnLogout,
  registerOnPresenceChanged,
  registerSetSupportedChannels
} from '@amc-technology/davinci-api';
import { Component, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { Application } from '@amc-technology/applicationangularframework';
import { Call } from '../../Model/PhoneCall';
import { CommunicationService } from '../../services/communication.service';
import { DeferredPromise } from '../../Model/DeferredPromise';
import { EVENT_NAMES } from '../../bridge/constants';
import { IPromiseToResolve } from '../../Model/IPromiseToResolve';
import { ISoftPhoneState } from '../../Model/ISoftPhoneState';
import { LoggerService } from '../../services/logger.service';
import { PROMISE_EVENT_TYPE } from '../../Model/PromiseEventTypes';
import { StorageService } from '../../services/storage.service';
import { StudioConfigService } from '../../services/studio-config.service';
import { bind } from 'bind-decorator';

@Component({
  selector: 'app-home',
  templateUrl: './home-byot.component.html'
})
export class HomeBYOTComponent extends Application implements OnInit {
  log: (logLevel: LOG_LEVEL, fName: string, message: string, object?: any, errorCode?: ERROR_CODE, localTime?: Date) => void;

  private interactionsList: Map<string, IInteraction> = new Map([]);
  private voiceCallIdToInteractionMap: Map<string, string> = new Map([]);

  private expiredInteractionsList: Map<string, IInteraction> = new Map([]);
  private presencesFromSalesforce: Map<string, string> = new Map([]);
  private promisesToResolve: IPromiseToResolve[] = [];
  private presencesToResolve: IPromiseToResolve[] = [];
  private currentPresence: string;
  private currentReason: string;
  private ctiId: string;
  private ctiExtension: string;
  private loggedIn = false;
  private failedHangupAttempts = 0;

  private state: ISoftPhoneState = {
    activeCalls: [],
    expiredCalls: [],
    agentStatus: ''
  };
  state$: Subject<ISoftPhoneState> = new Subject<ISoftPhoneState>();
  stateSubscriber: Subscription = this.state$.subscribe(this.setState);

  constructor(
    private loggerService: LoggerService,
    private storageService: StorageService,
    private scs: StudioConfigService,
    private communicationService: CommunicationService
  ) {
    super(loggerService.logger);
    this.log = this.loggerService.log;
    this.storageService.stateSubscriber = this.state$.subscribe(this.storageService.setState);
  }

  async ngOnInit() {
    const functionName = 'ngOnInit';
    try {
      await this.loadConfig();
      this.bridgeScripts = this.bridgeScripts.concat([this.getBridgeURL()]);
      await super.ngOnInit();

      this.getSalesforceConfig();
      this.checkInitialAgentStatus();
      this.registerForBridgeEvents();
      this.setup();

      registerOnPresenceChanged(this.setPresenceCallback);
      registerOnLogout(this.logoutCallback);
      registerSetSupportedChannels(this.getChannelConfig);

      this.log(LOG_LEVEL.Trace, functionName, 'Initialized home component');
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to initialized home component', error);
    }
  }

  async getSalesforceConfig() {
    const functionName = 'getSalesforceConfig';
    // This function pulls the user information from Salesforce for the presence maps and logic app
    try {
      const salesforceConfigs: {
        presences: any,
        callCenterApiName: string,
        orgUrl: string,
        orgId: string,
        crmId: string
      } = await this.bridgeEventsService.sendEvent(EVENT_NAMES.GET_SALESFORCE_CONFIG);
      this.log(LOG_LEVEL.Debug, functionName, 'Configs from Salesforce', salesforceConfigs);

      this.log(LOG_LEVEL.Debug, functionName, 'Populating Presence Map');
      const presenceMap = salesforceConfigs.presences;
      const presenceKeys = Object.keys(presenceMap);
      for (let i = 0; i < presenceKeys.length; i++) {
        const currentPresence = presenceMap[presenceKeys[i]];
        this.presencesFromSalesforce[currentPresence['statusName']] = currentPresence['statusId'];
      }
      this.log(LOG_LEVEL.Trace, functionName, 'Presence Map', this.presencesFromSalesforce);

      this.communicationService.initialize(
        salesforceConfigs.orgId,
        salesforceConfigs.orgUrl,
        salesforceConfigs.callCenterApiName,
        salesforceConfigs.crmId
      );
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to get salesforce configs', error);
    }
  }

  async checkInitialAgentStatus() {
    const functionName = 'checkInitialAgentStatus';
    try {
      const currentPresence: IGetPresenceResult = await getPresence();
      this.log(LOG_LEVEL.Debug, functionName, 'Current Presence', currentPresence);
      if (currentPresence.presence.toLowerCase() !== 'pending') {
        this.log(LOG_LEVEL.Debug, functionName, 'Sending Login event to Salesforce');
        this.bridgeEventsService.sendEvent(EVENT_NAMES.LOGIN);
      }
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to check agent status', error);
    }
  }

  public registerForBridgeEvents() {
    const functionName = 'registerForBridgeEvents';
    try {
      this.bridgeEventsService.subscribe(EVENT_NAMES.LOG, this.logFromBridge);
      this.bridgeEventsService.subscribe(EVENT_NAMES.LOGOUT, this.logoutUser);

      // State Management Events
      this.bridgeEventsService.subscribe(EVENT_NAMES.GET_ACTIVE_CALLS, this.getActiveCalls);
      this.bridgeEventsService.subscribe(EVENT_NAMES.SET_ACTIVE_CALLS, this.setActiveCalls);
      this.bridgeEventsService.subscribe(EVENT_NAMES.GET_EXPIRED_CALLS, this.getExpiredCalls);
      this.bridgeEventsService.subscribe(EVENT_NAMES.SET_EXPIRED_CALLS, this.setExpiredCalls);
      this.bridgeEventsService.subscribe(EVENT_NAMES.GET_AGENT_STATUS, this.getAgentStatus);
      this.bridgeEventsService.subscribe(EVENT_NAMES.PRESENCE_CHANGE, this.onPresenceChange);
      this.bridgeEventsService.subscribe(EVENT_NAMES.GET_PHONE_CONTACTS, this.getPhoneContacts);
      this.bridgeEventsService.subscribe(EVENT_NAMES.CALL_ENDED, this.updateCallDuration);

      // Call Control Events
      this.bridgeEventsService.subscribe(EVENT_NAMES.ACCEPT, this.acceptCallEvent);
      this.bridgeEventsService.subscribe(EVENT_NAMES.REJECT, this.rejectCallEvent);
      this.bridgeEventsService.subscribe(EVENT_NAMES.HANGUP, this.hangupCallEvent);
      this.bridgeEventsService.subscribe(EVENT_NAMES.MUTE, this.muteCallEvent);
      this.bridgeEventsService.subscribe(EVENT_NAMES.UNMUTE, this.unmuteCallEvent);
      this.bridgeEventsService.subscribe(EVENT_NAMES.HOLD, this.holdCallEvent);
      this.bridgeEventsService.subscribe(EVENT_NAMES.UNHOLD, this.unholdCallEvent);
      this.bridgeEventsService.subscribe(EVENT_NAMES.ADD_PARTICIPANT, this.addParticipant);
      this.bridgeEventsService.subscribe(EVENT_NAMES.WARM_TRANSFER, this.warmTransfer);
      this.bridgeEventsService.subscribe(EVENT_NAMES.BLIND_TRANSFER, this.blindTransfer);
      this.bridgeEventsService.subscribe(EVENT_NAMES.CONFERENCE, this.conference);
      this.bridgeEventsService.subscribe(EVENT_NAMES.REMOVE_PARTICIPANT, this.removeParticipant);
      // this.bridgeEventsService.subscribe('wrapup', this.wrapupCompleted);
      this.bridgeEventsService.subscribe(EVENT_NAMES.OUTBOUND, this.sendOutbound);
      this.bridgeEventsService.subscribe(EVENT_NAMES.SEND_DIGITS, this.sendDigits);
      this.bridgeEventsService.subscribe(EVENT_NAMES.SET_OUTBOUND_CALL_ID, this.setOutboundCallId);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to register for bridge events', error);
    }
  }

  public async setup(): Promise<void> {
    const functionName = 'setup';
    try {
      const configs = {
        addCallerEnabled: this.scs.addCallerEnabled,
        conferenceEnabled: this.scs.conferenceEnabled,
        holdEnabled: this.scs.holdEnabled,
        muteEnabled: this.scs.muteEnabled,
        removeParticipantVariant: this.scs.removeParticipantVariant,
        swapEnabled: this.scs.swapEnabled,
        rejectEnabled: this.scs.rejectEnabled,
        contactList: this.scs.contactList,
        queueMapping: this.scs.ctiIdToSalesforceIdMap,
        queueWaitTime: this.scs.queueWaitTime
      };
      this.log(LOG_LEVEL.Debug, functionName, 'Sending configs to bridge', configs);
      await this.bridgeEventsService.sendEvent(EVENT_NAMES.SEND_STUDIO_CONFIGS, configs);
      this.log(LOG_LEVEL.Trace, functionName, 'Setup complete');
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to get studio configs', error);
    }
  }

  @bind
  async getChannelConfig(originalAppName: string, channels: ISupportedChannel[]) {
    const functionName = 'getChannelConfig';
    try {
      // This callback will set the values for the ctiId and ctiExtension needed for the logic app
      this.log(LOG_LEVEL.Information, functionName, 'Channel Configs', channels);
      // TODO: Determine if this needs to be kept or if it can be removed
      this.ctiId = channels[0].id;
      this.ctiExtension = channels[0]['ctiExtension'];
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to get channel configs', error);
    }
  }


  /*
  * Functions to handle interactions from DaVinci
  */
  protected async onInteraction(interaction: IInteraction): Promise<SearchRecords> {
    const functionName = 'onInteraction';
    try {
      this.log(LOG_LEVEL.Debug, functionName, 'Received Interaction', { interactionId: interaction.interactionId, scenarioId: interaction.scenarioId, state: interaction.state, direction: interaction.direction });
      this.log(LOG_LEVEL.Trace, functionName, 'Received Interaction Details', { interaction, interactionsList: this.interactionsList });

      if (this.voiceCallIdToInteractionMap.has(interaction.interactionId)) {
        this.log(LOG_LEVEL.Loop, functionName, 'Adding VoiceCallId to Interaction from Map', {
          interactionId: interaction.interactionId,
          scenarioId: interaction.scenarioId ,
          voiceCallId: this.voiceCallIdToInteractionMap.get(interaction.interactionId)
        });
        interaction.details.fields['SalesforceCallId'] = {
          DevName: 'SalesforceCallId',
          DisplayName: 'SalesforceCallId',
          Value: this.voiceCallIdToInteractionMap.get(interaction.interactionId)
        };
      } else {
        this.log(LOG_LEVEL.Loop, functionName, 'No VoiceCallId to Interaction from Map', {
          interactionId: interaction.interactionId,
          scenarioId: interaction.scenarioId,
          map: this.voiceCallIdToInteractionMap
        });
      }

      if (interaction.transcripts) {
        // If there is a transcription on the interaction it will not be used to resolve a promise or raise an event to Salesforce
        this.processTranscription(interaction);
      } else {
        const resolvedAPromise = await this.checkForActivePromises(interaction);
        if (resolvedAPromise) {
          this.log(LOG_LEVEL.Trace, functionName, 'Resolved a promise, nothing to process', {
            interactionId: interaction.interactionId,
            scenarioId: interaction.scenarioId,
            state: interaction.state,
            direction: interaction.direction
          });

          if (interaction.state === INTERACTION_STATES.Disconnected) {
            this.log(LOG_LEVEL.Trace, functionName, 'Interaction is disconnected, updating end time via REST API for call duration', {
              interactionId: interaction.interactionId,
              scenarioId: interaction.scenarioId,
              state: interaction.state,
              direction: interaction.direction
            });

            this.updateCallDuration({ interactionId: interaction.interactionId, endTime: new Date() });
          }
        } else {
          if (!this.interactionsList.has(interaction.interactionId)) {
            this.log(LOG_LEVEL.Trace, functionName, 'Interaction not in list, processing', {
              interactionId: interaction.interactionId,
              scenarioId: interaction.scenarioId,
              state: interaction.state, direction:
              interaction.direction
            });
            this.processInteraction(interaction);
          } else if (
            this.interactionsList.has(interaction.interactionId) &&
            this.interactionsList.get(interaction.interactionId).state !== interaction.state
          ) {
            this.log(LOG_LEVEL.Trace, functionName, 'Interaction in list has a different state, processing', {
              interactionId: interaction.interactionId,
              scenarioId: interaction.scenarioId,
              state: interaction.state,
              direction: interaction.direction
            });
            this.processInteraction(interaction);
          } else {
            this.log(LOG_LEVEL.Trace, functionName, 'Interaction in list has the same state, nothing to process', {
              interactionId: interaction.interactionId,
              scenarioId: interaction.scenarioId,
              state: interaction.state,
              direction: interaction.direction
            });
          }
        }
      }

      // Update the interactionsList with the most recent interaction
      this.updateInteractionList(interaction);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to process interaction', error);
      return Promise.reject(error);
    }
  }

  private processTranscription(interaction: IInteraction) {
    const functionName = 'processTranscription';
    try {
      this.log(LOG_LEVEL.Debug, functionName, 'Processing Transcription', { interactionId: interaction.interactionId, scenarioId: interaction.scenarioId, transcripts: interaction.transcripts });

      let vendorCallKey: string;
      if (this.scs.VendorCallKeyCAD) {
        vendorCallKey = this.voiceCallIdToInteractionMap.get(interaction.interactionId);
        this.log(LOG_LEVEL.Debug, functionName, 'Using vendorCallKey for transcription.', vendorCallKey);
      }

      this.communicationService.sendTranscription(interaction, vendorCallKey);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to process transcription', error);
    }
  }

  private async processInteraction(interaction: IInteraction) {
    const functionName = 'processInteraction';
    try {
      this.log(LOG_LEVEL.Debug, functionName, 'Processing Interaction', { interactionId: interaction.interactionId, scenarioId: interaction.scenarioId, state: interaction.state, direction: interaction.direction });
      if (interaction.state === INTERACTION_STATES.Alerting) {
        this.sendInbound(interaction);
      } else if (interaction.state === INTERACTION_STATES.Connected) {
        this.sendConnected(interaction);
      } else if (interaction.state === INTERACTION_STATES.Disconnected) {
        this.sendDisconnected(interaction);
      } else if (interaction.state === INTERACTION_STATES.OnHold) {
        this.sendOnHold(interaction);
      } else if (interaction.state === INTERACTION_STATES.Initiated) {
        this.sendInitiated(interaction);
      }
      this.log(LOG_LEVEL.Trace, functionName, 'Processing Interaction Details', { interaction, interactionsList: this.interactionsList });
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to process interaction', error);
    }
  }

  private updateInteractionList(interaction: IInteraction) {
    const functionName = 'updateInteractionList';
    try {
      this.interactionsList.set(interaction.interactionId, interaction);
      this.storageService.updateInteractionList(this.interactionsList);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error updating interactionList', error);
    }
  }


  /*
  * Functions to handle deferred promises
  */
  async checkForActivePromises(interaction: IInteraction): Promise<Boolean> {
    const functionName = 'checkForActivePromises';
    try {
      this.log(LOG_LEVEL.Debug, functionName, 'Checking for active promises', [
        interaction.interactionId,
        interaction.scenarioId,
        interaction.state,
        interaction.direction,
        interaction?.details?.fields?.Phone?.Value
      ]);
      if (this.promisesToResolve.length > 0) {
        this.log(LOG_LEVEL.Trace, functionName, 'Pending Promises', this.promisesToResolve);
        for (let i = 0; i < this.promisesToResolve.length; i++) {
          const currentPromise = this.promisesToResolve[i];
          if (this.checkIfPromiseIsExpired(currentPromise)) {

            switch (interaction.state) {
              case INTERACTION_STATES.Connected: // Connected Events
                return await this.checkConnectedPromise(interaction, currentPromise, i);
              case INTERACTION_STATES.Disconnected: // Disconnected Events
                return await this.checkDisconnectedPromise(interaction, currentPromise, i);
              case INTERACTION_STATES.Initiated: // Initiated Events
                return await this.checkInitiatedPromise(interaction, currentPromise, i);
              case INTERACTION_STATES.OnHold: // OnHold Events
                return await this.checkOnHoldPromise(interaction, currentPromise, i);
              case INTERACTION_STATES.Alerting: // Alerting Events
                // return await this.checkAlertingPromise(interaction, currentPromise, i);
              default:
                this.log(LOG_LEVEL.Loop, functionName, 'Cannot resolve promise with this interaction.', { currentPromise, interaction });
                break;
              }
            }

          }
      } else {
        this.log(LOG_LEVEL.Trace, functionName, 'No Promises to resolve');
        return false;
      }
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to check for active promises', error);
      return false;
    }
  }

  checkIfPromiseIsExpired(promise: IPromiseToResolve, presencePromise: boolean = false) {
    const functionName = 'checkIfPromiseIsExpired';
    try {
      if (Date.now() - promise.deferredPromise.timeCreated > (presencePromise ? this.scs.presenceChangeTimeout : this.scs.eventTimeout)) {
        this.log(LOG_LEVEL.Warning, functionName, 'Promise expired.', promise);
        this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.all, functionName, presencePromise ? 'Presence expired' : 'Promise expired');
        return false;
      }
      return true;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to check if promise is expired', error);
    }
  }

  async checkConnectedPromise(interaction: IInteraction, currentPromise: IPromiseToResolve, i: number) {
    const functionName = 'checkConnectedPromise';
    try {
      if (interaction.scenarioId === currentPromise.scenarioId) {
        if (interaction.multiPartyState === INTERACTION_MULTIPARTY_STATES.Conferenced) {
          return await this.resolveDeferredPromise(interaction, i);
        }
        if (interaction.interactionId === currentPromise.interactionId) {
          // TODO: Phone number formatting might have to occur before this check
          if (currentPromise.eventData['interactions'][currentPromise.eventData['interactions'].length - 1].details.fields.Phone.Value === interaction.details.fields.Phone.Value) {
            if (currentPromise.eventType === PROMISE_EVENT_TYPE.accepted) {
              return await this.resolveDeferredPromise(interaction, i);
            } else if (currentPromise.eventType === PROMISE_EVENT_TYPE.connected) {
              return await this.resolveDeferredPromise(interaction, i);
            } else if (currentPromise.eventType === PROMISE_EVENT_TYPE.unheld) {
              return await this.resolveDeferredPromise(interaction, i);
            } else if (currentPromise.eventType === PROMISE_EVENT_TYPE.unmuted) {
              return await this.resolveDeferredPromise(interaction, i);
            } else if (currentPromise.eventType === PROMISE_EVENT_TYPE.muted) {
              return await this.resolveDeferredPromise(interaction, i);
            } else if (currentPromise.eventType === PROMISE_EVENT_TYPE.held) {
              return await this.resolveDeferredPromise(interaction, i);
            }
          }
          if (currentPromise.eventType === PROMISE_EVENT_TYPE.removeParticipant) {
            return await this.resolveDeferredPromise(interaction, i);
          }
        } else {
          // if (currentPromise.eventData === interaction.details.fields.Phone.Value) {
          // Todo: implement phonenumber formatting here
          if (currentPromise.eventType === PROMISE_EVENT_TYPE.addParticipant) {
            return await this.resolveDeferredPromise(interaction, i);
          }
          // }
        }
      } else {
        if (currentPromise.eventType === PROMISE_EVENT_TYPE.outbound) {
          return await this.resolveDeferredPromise(interaction, i);
        }
      }
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to check connected promise', error);
    }
  }

  async checkDisconnectedPromise(interaction: IInteraction, currentPromise: IPromiseToResolve, i: number) {
    const functionName = 'checkDisconnectedPromise';
    try {
      if (interaction.scenarioId === currentPromise.scenarioId) {
        if (interaction.interactionId === currentPromise.interactionId) {
          this.interactionsList.delete(interaction.interactionId);
          if (currentPromise.eventType === PROMISE_EVENT_TYPE.hangup) {
            return await this.resolveDeferredPromise(interaction, i);
          } else if (currentPromise.eventType === PROMISE_EVENT_TYPE.warmTransferred) {
            return await this.resolveDeferredPromise(interaction, i);
          } else if (currentPromise.eventType === PROMISE_EVENT_TYPE.blindTransferred) {
            return await this.resolveDeferredPromise(interaction, i);
          }
        }
      }
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to check disconnected promise', error);
    }
  }

  async checkInitiatedPromise(interaction: IInteraction, currentPromise: IPromiseToResolve, i: number) {
    const functionName = 'checkInitiatedPromise';
    try {
      if (interaction.scenarioId === currentPromise.scenarioId) {
        if (interaction.interactionId === currentPromise.interactionId) {
        } else {
          if (currentPromise.eventData === interaction.details.fields.Phone.Value) {
            if (currentPromise.eventType === PROMISE_EVENT_TYPE.addParticipant) {
              return await this.resolveDeferredPromise(interaction, i);
            }
          }
        }
      } else if (currentPromise.scenarioId === '' && currentPromise.interactionId === '') {
        if (currentPromise.eventType === PROMISE_EVENT_TYPE.outbound) {
          return await this.resolveDeferredPromise(interaction, i);
        }
      }
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to check initiated promise', error);
    }
  }

  async checkOnHoldPromise(interaction: IInteraction, currentPromise: IPromiseToResolve, i: number) {
    const functionName = 'checkOnHoldPromise';
    try {
      if (interaction.scenarioId === currentPromise.scenarioId) {
        if (interaction.interactionId === currentPromise.interactionId) {
          if (currentPromise.eventData['interactions'][currentPromise.eventData['interactions'].length - 1]
            .details.fields.Phone.Value === interaction.details.fields.Phone.Value) {
            return await this.resolveDeferredPromise(interaction, i);
          }
        }
      }
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to check on hold promise', error);
    }
  }

  async checkAlertingPromise(interaction: IInteraction, currentPromise: IPromiseToResolve, i: number) {
    const functionName = 'checkAlertingPromise';
    try {
      if (interaction.scenarioId === currentPromise.scenarioId) {
        if (interaction.interactionId === currentPromise.interactionId) {
        // TODO: Identify scenarios where this is needed
        }
      }
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to check alerting promise', error);
    }
  }

  async resolveDeferredPromise(interaction: IInteraction, i: number) {
    const functionName = 'resolveDeferredPromise';
    try {
      // This function removes the promise from the list and resolves it
      const promiseToResolve: DeferredPromise = this.promisesToResolve[i].deferredPromise;
      promiseToResolve.resolve(interaction);
      const removedPromise = this.promisesToResolve.splice(i, 1);
      this.log(LOG_LEVEL.Trace, functionName, 'Promise Resolved', { removedPromise, interactionId: interaction.interactionId, scenarioId: interaction.scenarioId});
      return true;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to resolve promise', error);
      return false;
    }
  }

  private rejectPromiseTimeout(promiseType: PROMISE_EVENT_TYPE, originalFunctionName: string, event: any) {
    const functionName = 'rejectPromiseTimeout';
    try {
      this.log(LOG_LEVEL.Trace, functionName, 'Checking for timed out promises', { promiseType, originalFunctionName, event });
      if (this.promisesToResolve.length > 0) {
        this.log(LOG_LEVEL.Trace, functionName, 'Promises to resolve', this.promisesToResolve);
        for (let i = 0; i < this.promisesToResolve.length; i++) {
          const promiseToReject: DeferredPromise = this.promisesToResolve[i].deferredPromise;
          if ((Date.now() - promiseToReject.timeCreated) > this.scs.eventTimeout) {
            if (this.promisesToResolve[i].eventType === promiseType || promiseType === PROMISE_EVENT_TYPE.all) {
              this.log(LOG_LEVEL.Trace, functionName, 'Promise timed out', promiseToReject);
              promiseToReject.reject(event);
              this.promisesToResolve.splice(i, 1);
            }
          }
        }
      }
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to reject promise', error);
    }
  }

  private rejectPresenceTimeout(promiseType: PROMISE_EVENT_TYPE, originalFunctionName: string, event: any) {
    const functionName = 'rejectPresenceTimeout';
    try {
      this.log(LOG_LEVEL.Trace, functionName, 'Checking for timed out presences', { promiseType, originalFunctionName, event });
      if (this.presencesToResolve.length > 0) {
        this.log(LOG_LEVEL.Trace, functionName, 'Presences to resolve', this.presencesToResolve);
        for (let i = 0; i < this.presencesToResolve.length; i++) {
          const presenceToReject: DeferredPromise = this.presencesToResolve[i].deferredPromise;
          if ((Date.now() - presenceToReject.timeCreated) > this.scs.presenceChangeTimeout) {
            this.log(LOG_LEVEL.Warning, functionName, 'Presence timed out', presenceToReject);
            presenceToReject.reject(event);
            this.presencesToResolve.splice(i, 1);
          }
        }
      }
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to reject presence', error);
    }
  }

  /*
  * Functions to handle state from the bridge
  */
  initializeState() {
    const functionName = 'checkAndUpdateState';
    try {
        const storedState: ISoftPhoneState = this.storageService.getCurrentState();
        if (!storedState) {
          this.log(LOG_LEVEL.Loop, functionName, 'No state in local storage, storing current state');
          this.state$.next(this.state);
        } else {
          this.log(LOG_LEVEL.Trace, functionName, 'State initialized from local storage', storedState);
          this.state$.next(storedState);
        }
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error checking and updating state', error);
    }
  }

  /**
   ** This function will update the state of the home component when the state observable is updated.
   * DO NOT CALL THIS FUNCTION DIRECTLY
   *
   * @private
   * @param {ISoftPhoneState} state
   * @memberof HomeBYOTComponent
   */
  @bind
  private setState(state: ISoftPhoneState) {
    const functionName = 'setState';
    try {
      this.state = state;
      this.log(LOG_LEVEL.Loop, functionName, 'State updated', state);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error setting state', error);
    }
  }

  @bind
  protected async setPresenceCallback(presence: string, reason?: string, initiatingApp?: string): Promise<any> {
    const functionName = 'setPresenceCallback';
    try {
      this.log(LOG_LEVEL.Debug, functionName, 'Presence Change', { presence, reason, initiatingApp });
      if (this.loggedIn === false && presence !== 'Pending') {
        this.log(LOG_LEVEL.Debug, functionName, 'Sending Login event to Salesforce');
        this.bridgeEventsService.sendEvent(EVENT_NAMES.LOGIN);
        this.loggedIn = true;
      }
      this.currentPresence = presence;
      this.currentReason = reason;
      if (presence === 'On an interaction') {
        // TODO: Identify if this event still needs to be ignored
        this.log(LOG_LEVEL.Trace, functionName, 'Presence is On an Interaction, nothing sent to Salesforce.');
        return;
      }
      let presenceSet = false;
      if (this.presencesToResolve.length > 0) {
        this.log(LOG_LEVEL.Trace, functionName, 'Presences to resolve', this.presencesToResolve.length);
        for (let i = 0; i < this.presencesToResolve.length; i++) {
          const presenceToResolve: DeferredPromise = this.presencesToResolve[i].deferredPromise;
          if (this.checkIfPromiseIsExpired(this.presencesToResolve[i], true)) {
            this.log(LOG_LEVEL.Trace, functionName, 'Resolving Presence Promise', presenceToResolve);
            presenceToResolve.resolve(presence);
            this.presencesToResolve.splice(i, 1);
            presenceSet = true;
          } else {
            this.log(LOG_LEVEL.Trace, functionName, 'Presence Promise Expired', presenceToResolve);
            presenceToResolve.reject('Presence Change Timed Out');
          }
        }
      }
      if (!presenceSet) {
        this.log(LOG_LEVEL.Trace, functionName, 'No presence promises to resolve, sending presence to Salesforce', { presence, reason });
        // This event raises a presence change event that was not triggered by Salesforce
        let presenceId = this.presencesFromSalesforce[this.scs.channelToSalesforceMap[presence]];
        if (reason !== null && reason !== '') {
          presenceId = this.presencesFromSalesforce[this.scs.channelToSalesforceMap[`${presence}|${reason}`]];
        }
        this.bridgeEventsService.sendEvent(EVENT_NAMES.PRESENCE, presenceId);
      }
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to set presence', error);
      return Promise.reject(false);
    }
  }

  @bind
  async getActiveCalls(): Promise<Call[]> {
    const functionName = 'getActiveCalls';
    try {
      this.log(LOG_LEVEL.Trace, functionName, 'Getting Active Calls');
      return this.state.activeCalls;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to get active calls', error);
    }
  }

  @bind
  setActiveCalls(activeCalls: Call[]) {
    const functionName = 'setActiveCalls';
    try {
      this.log(LOG_LEVEL.Trace, functionName, 'Setting Active Calls', activeCalls);
      this.state.activeCalls = activeCalls;
      this.state$.next(this.state);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to set active calls', error);
    }
  }

  @bind
  async getExpiredCalls() {
    const functionName = 'getExpiredCalls';
    try {
      this.log(LOG_LEVEL.Trace, functionName, 'Getting Expired Calls');
      return this.state.expiredCalls;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to get expired calls', error);
    }
  }

  @bind
  setExpiredCalls(expiredCalls: Call[]) {
    const functionName = 'setExpiredCalls';
    try {
      this.log(LOG_LEVEL.Trace, functionName, 'Setting Expired Calls', expiredCalls);
      this.state.expiredCalls = expiredCalls;
      this.state$.next(this.state);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to set expired calls', error);
    }
  }

  @bind
  async getAgentStatus() {
    const functionName = 'getAgentStatus';
    try {
      this.log(LOG_LEVEL.Trace, functionName, 'Getting Agent Status', this.state.agentStatus);
      return this.state.agentStatus;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to get agent status', error);
    }
  }

  @bind
  async setAgentStatus(agentStatus: string) {
    const functionName = 'setAgentStatus';
    try {
      this.log(LOG_LEVEL.Trace, functionName, 'Setting Agent Status', agentStatus);
      this.state.agentStatus = agentStatus;
      this.state$.next(this.state);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to set agent status', error);
    }
  }

/**
 ** This function is responsible for logging out the user when an event is received from DaVinci and for resolving a logout promise if it exists.
 *
 * @private
 * @param {string} [reason]
 * @memberof HomeBYOTComponent
 */
  @bind
  private async logoutCallback(reason?: string) {
    const functionName = 'logoutCallback';
    try {
      this.log(LOG_LEVEL.Debug, functionName, 'Received logout Event', reason);
      await this.logger.pushLogsAsync();
      let promiseResolved = false;
      if (this.promisesToResolve.length > 0) {
        this.log(LOG_LEVEL.Trace, functionName, 'Checking for Logout Promise', this.promisesToResolve);
        for (let i = 0; i < this.promisesToResolve.length; i++) {
          if (this.promisesToResolve[i].eventType === PROMISE_EVENT_TYPE.logout) {
            promiseResolved = true;
            const promiseToResolve: DeferredPromise = this.promisesToResolve[i].deferredPromise;
            this.log(LOG_LEVEL.Trace, functionName, 'Resolving Logout Promise', promiseToResolve);
            await promiseToResolve.resolve(reason);
            this.promisesToResolve.splice(i, 1);
            break;
          }
        }
      }
      if (!promiseResolved) {
        this.bridgeEventsService.sendEvent(EVENT_NAMES.LOGOUT);
      }
      this.removeLocalStorageOnLogout();
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to logout', error);
    }
  }

/**
 ** This function raises a logout event to DaVinci and creates a promise to resolve when the logout event is processed by DaVinci.
 *
 * @return {*}
 * @memberof HomeBYOTComponent
 */
  @bind
  logoutUser() {
    const functionName = 'logoutUser';
    try {
      // Todo: When the agent is set to offline in the softphone the agent needs to be signed out of agent
      this.log(LOG_LEVEL.Debug, functionName, 'Logging out user');
      this.promisesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.logout,
        scenarioId: '',
        interactionId: '',
        deferredPromise: new DeferredPromise(),
        eventData: null
      });
      this.loggedIn = false;
      logout();
      return this.promisesToResolve[this.promisesToResolve.length - 1].deferredPromise.promise;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to logout user', error);
    }
  }

  @bind
  async onPresenceChange(
    agentPresence: {
      agentStatus: string,
      agentStatusInfo: {
        statusId: string,
        statusApiName: string,
        statusName: string
      },
      enqueueNextState: boolean
  }) {
    const functionName = 'onPresenceChange';
    try {
      this.log(LOG_LEVEL.Debug, functionName, 'Presence Change Event from Salesforce', agentPresence);

      const pending = agentPresence.enqueueNextState;
      if (pending) {
        agentPresence.agentStatusInfo.statusName = 'Pending ' + agentPresence.agentStatusInfo.statusName;
      }

      const presenceMapped = this.scs.salesforceToChannelMap[agentPresence.agentStatusInfo.statusName];

      if (presenceMapped === undefined) {
        this.log(LOG_LEVEL.Warning, functionName, 'Presence map not configured', agentPresence);
        return Promise.reject();
      }
      this.log(LOG_LEVEL.Trace, functionName, 'Mapped Presence', presenceMapped);

      const presenceArray = presenceMapped.split('|');
      const presence = presenceArray[0];
      let reason = '';

      if (presenceArray.length > 1) {
        // This will split a presence if it maps to a reason code using the | character
        reason = presenceArray[1];
        this.log(LOG_LEVEL.Trace, functionName, 'Mapped Reason', reason);
      }

      this.log(LOG_LEVEL.Trace, functionName, 'Current Presence', { currentPresence: this.currentPresence, currentReason: this.currentReason });
      this.log(LOG_LEVEL.Debug, functionName, 'Presence', { presence, reason, pending });
      if (this.currentPresence === presence && this.currentReason === reason) {
        this.log(LOG_LEVEL.Warning, functionName, 'Agent is already in this presence', { presence, reason, pending });
        return Promise.resolve(presence);
      }
      this.log(LOG_LEVEL.Trace, functionName, 'Calling ClickToAct() to SETPRESENCE call..', { presence, reason, pending });
      this.presencesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.presence,
        scenarioId: '',
        interactionId: '',
        deferredPromise: new DeferredPromise(),
        eventData: { 'presence': presence, 'reason': reason, 'pending': pending }
      });
      clickToAct(
        '',
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.SetPresence,
        '',
        { presence: presence, reason: reason, pending: pending }
      );
      setTimeout(() => this.rejectPresenceTimeout(PROMISE_EVENT_TYPE.presence, functionName, agentPresence), this.scs.presenceChangeTimeout);
      this.log(LOG_LEVEL.Trace, functionName, 'Added to presences to resolve', this.presencesToResolve);
      return this.presencesToResolve[this.presencesToResolve.length - 1].deferredPromise.promise;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to change presence', error);
      return Promise.reject(`Failed to set presence`);
    }
  }

  @bind
  async getPhoneContacts() {
    const functionName = 'getPhoneContacts';
    try {
      const contacts = await getLiveAgents();
      return contacts;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to get live agents', error);
      return Promise.reject('Failed to perform blind transfer');
    }
  }

  @bind
  async updateCallDuration(callDetails: { interactionId: string, endTime: Date }) {
    const functionName = 'updateCallDuration';
    try {
      const voiceCallRecordDetails = this.storageService.interactionToVoiceCallRecordDetailsMap.get(callDetails.interactionId);

      if (voiceCallRecordDetails) {
        this.storageService.interactionToVoiceCallRecordDetailsMap.delete(callDetails.interactionId);

        this.communicationService.updateCall({
          voiceCallId: voiceCallRecordDetails.voiceCallId,
          startTime: voiceCallRecordDetails.startTime,
          endTime: callDetails.endTime
        });

        this.communicationService.deregisterAgentIdForUCID(voiceCallRecordDetails.voiceCallId);

      } else {
        this.log(LOG_LEVEL.Warning, functionName, 'VoiceCallRecordDetails not found', callDetails);
      }

    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to get live agents', error);
      return Promise.reject('Failed to perform blind transfer');
    }
  }

  /*
   * Event functions that send events to the bridge for BYOT to handle
   */
  protected async sendInbound(interaction: IInteraction) {
    const functionName = 'sendInbound';
    try {
      this.log(LOG_LEVEL.Trace, functionName, 'Sending Inbound Event', {
        interactionId: interaction.interactionId,
        scenarioId: interaction.scenarioId,
        phone: interaction.details.fields.Phone?.Value,
        dialedPhone: interaction.details.fields.DialedPhone?.Value
      });

      if (!interaction.details.fields.DialedPhone?.Value) {
        interaction.details.setDialedPhone('DialedPhone', 'DialedPhone', this.ctiId);
      }

      let vendorCallKey: string;
      if (this.scs.VendorCallKeyCAD) {
        if (!interaction.details?.fields?.[this.scs.VendorCallKeyCAD]?.Value) {
          this.log(LOG_LEVEL.Warning, functionName, 'VendorCallKeyCAD is not present in the interaction, using interactionId instead', {
            interactionId: interaction.interactionId,
            vendorCallKey: this.scs.VendorCallKeyCAD,
            CAD: interaction.details.fields
          });
          vendorCallKey = this.communicationService.formatInteractionId(interaction.interactionId);
        } else {
          vendorCallKey = interaction.details.fields[this.scs.VendorCallKeyCAD].Value;
          this.log(LOG_LEVEL.Debug, functionName, 'CAD with vendorCallKey found', { interactionId: interaction.interactionId, vendorCallKey });
        }
      }

      let voiceCallId: string;
      const startTime = new Date();

      if (!this.scs.clientSideCallCreation) {
        this.log(LOG_LEVEL.Debug, functionName, 'Creating Call using communication service', { interactionId: interaction.interactionId });
        voiceCallId = await this.communicationService.createCall(interaction, startTime, vendorCallKey);
        this.communicationService.registerUCIDAndAgentId(voiceCallId);
      } else {
        voiceCallId = vendorCallKey ?? this.communicationService.formatInteractionId(interaction.interactionId);
      }

      this.storageService.interactionToVoiceCallRecordDetailsMap.set(interaction.interactionId, {
        voiceCallId: voiceCallId,
        startTime: startTime
      });

      this.log(LOG_LEVEL.Debug, functionName, `Using ${vendorCallKey ? 'vendorCallKey' : 'interactionId'} as voiceCallId`, { interactionId: interaction.interactionId, vendorCallKey, voiceCallId });
      this.voiceCallIdToInteractionMap.set(interaction.interactionId, vendorCallKey ?? voiceCallId);
      this.storageService.updateVoiceCallIdToInteractionMap(this.voiceCallIdToInteractionMap);
      interaction.details.fields['SalesforceCallId'] = {
        DevName: 'SalesforceCallId',
        DisplayName: 'SalesforceCallId',
        Value: vendorCallKey ?? voiceCallId
      };

      this.bridgeEventsService.sendEvent(EVENT_NAMES.INBOUND, { 'interaction': interaction, 'voiceCallId': voiceCallId });
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to send inbound event', error);
    }
  }

  protected async sendConnected(interaction: IInteraction) {
    const functionName = 'sendConnected';
    try {
      this.log(LOG_LEVEL.Debug, functionName, 'Sending Connected Event', { interactionId: interaction.interactionId, scenarioId: interaction.scenarioId, phone: interaction.details.fields['Phone']['Value']});
      await this.bridgeEventsService.sendEvent(EVENT_NAMES.CONNECTED, {
        'interaction': interaction,
        'voiceCallId': this.voiceCallIdToInteractionMap.get(interaction.interactionId)
      });
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to send connected event', error);
    }
  }

  protected async sendDisconnected(interaction: IInteraction) {
    const functionName = 'sendDisconnected';
    try {
      this.log(LOG_LEVEL.Debug, functionName, 'Sending Disconnected Event', { interactionId: interaction.interactionId, scenarioId: interaction.scenarioId});
      if (this.interactionsList.has(interaction.interactionId)) {
        this.expiredInteractionsList.set(interaction.interactionId, interaction);
        this.interactionsList.delete(interaction.interactionId);
      }
      await this.bridgeEventsService.sendEvent(EVENT_NAMES.DISCONNECTED, interaction);
      this.failedHangupAttempts = 0;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to send disconnected event', error);
    }
  }

  protected async sendOnHold(interaction: IInteraction) {
    const functionName = 'sendOnHold';
    try {
      this.log(LOG_LEVEL.Debug, functionName, 'Sending Hold Event', { interactionId: interaction.interactionId, scenarioId: interaction.scenarioId});

      await this.bridgeEventsService.sendEvent(EVENT_NAMES.ON_HOLD, interaction);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to send on hold event', { interactionId: interaction.interactionId, scenarioId: interaction.scenarioId, error});
    }
  }

  protected async sendInitiated(interaction: IInteraction) {
    const functionName = 'sendInitiated';
    try {
      this.log(LOG_LEVEL.Debug, functionName, 'Sending Initiated Event', { interactionId: interaction.interactionId, scenarioId: interaction.scenarioId});
      await this.bridgeEventsService.sendEvent(EVENT_NAMES.INITIATED, interaction);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to send initiated event', { interactionId: interaction.interactionId, scenarioId: interaction.scenarioId, error});
    }
  }

  /*
   *  Callback functions for the bridge that handle call controls
   */
  @bind
  async acceptCallEvent(call: Call): Promise<any> {
    const functionName = 'acceptCallEvent';
    try {
      if (!call) {
        this.log(LOG_LEVEL.Warning, functionName, 'Accepted Call is null.', this.interactionsList);
        return;
      }

      let noInteractions = false;

      // If the call does not have the necessary data, attempt recovery
      if (!call['interactions'] || call.interactions.length < 1 || !call.interactions[call.interactions.length - 1]) {
        this.log(LOG_LEVEL.Debug, functionName, 'Call has no interactions. Attempting to recover.', this.interactionsList);
        const storedCalls = this.state.activeCalls;
        // No hope if stored call has no interactions either
        if (
          !storedCalls[call.callId] ||
          !storedCalls[call.callId]['interactions'] ||
          storedCalls[call.callId].interactions.length < 1
        ) {
          this.log(LOG_LEVEL.Debug, functionName, 'Could not recall interactions from localStorage');
          noInteractions = true;
        } else {
          // Reapply the stored interactions onto the Call object
          this.log(LOG_LEVEL.Trace, functionName, 'Reapplying stored interactions onto call object', this.interactionsList);
          call['interactions'] = storedCalls[call.callId].interactions;
        }
      }

      // Remove null and undefined values if present
      const validInteractions = noInteractions ? null : call['interactions']
        .filter(interaction => interaction && interaction['interactionId']);

      if (await this.callAlreadyInState(call, INTERACTION_STATES.Connected, null)) {
        // Call already disconnected, return the interaction
        this.log(LOG_LEVEL.Warning, functionName, 'Call already accepted, returning interaction..', this.interactionsList);
        const earlyInteraction: any = await this.callAlreadyInState(call, INTERACTION_STATES.Connected);
        return earlyInteraction;
      }

      this.log(LOG_LEVEL.Trace, functionName, 'Calling ClickToAct() to ACCEPT call..', call);
      this.promisesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.accepted,
        scenarioId: call.interactions[call.interactions.length - 1].scenarioId,
        interactionId: call.interactions[call.interactions.length - 1].interactionId,
        deferredPromise: new DeferredPromise(),
        eventData: call
      });
      this.log(LOG_LEVEL.Trace, functionName, 'Added to promises to resolve', this.promisesToResolve);
      clickToAct(
        noInteractions ? null : call.interactions[call.interactions.length - 1].details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.Answer,
        noInteractions ? null : call.interactions[call.interactions.length - 1].interactionId,
        call
      );
      setTimeout(() => this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.accepted, functionName, call), this.scs.eventTimeout);
      return this.promisesToResolve[this.promisesToResolve.length - 1].deferredPromise.promise;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to accept call', error);
    }
  }

  @bind
  async rejectCallEvent(call: Call) {
    const functionName = 'rejectCallEvent';
    // Todo: Determine a way to get a response on the success case for a rejection event
    try {
      return clickToAct(
        call.interactions[call.interactions.length - 1].details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.Reject,
        call.interactions[call.interactions.length - 1].interactionId,
        call
      );
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to reject call', error);
    }
  }

  @bind
  async hangupCallEvent(call: Call) {
    const functionName = 'hangupCallEvent';
    try {
      this.log(LOG_LEVEL.Debug, functionName, 'Hangup Call Event', call);
      let noInteractions = false;

      // If the call does not have the necessary data, attempt recovery
      if (!call['interactions'] || call.interactions.length < 1 || !call.interactions[call.interactions.length - 1]) {
        this.log(LOG_LEVEL.Warning, functionName, 'Call has no interactions. Attempting to recover.');
        const storedCalls = this.state.activeCalls;
        // No hope if stored call has no interactions either
        if (!storedCalls[call.callId] ||
          !storedCalls[call.callId]['interactions'] ||
          storedCalls[call.callId].interactions.length < 1) {
          this.log(LOG_LEVEL.Warning, functionName, 'Could not recall interactions from localStorage');
          noInteractions = true;
        } else {
          // Reapply the stored interactions onto the Call object
          this.log(LOG_LEVEL.Trace, functionName, 'Reapplying stored interactions onto call object', this.interactionsList);
          call['interactions'] = storedCalls[call.callId].interactions;
        }

      }

      // Remove null and undefined values if present
      const validInteractions = noInteractions ? null : call['interactions']
        .filter(interaction => interaction && interaction['interactionId']);

      if (await this.callAlreadyInState(call, INTERACTION_STATES.Disconnected, null)) {
        // Call already disconnected, return the interaction
        this.log(LOG_LEVEL.Warning, functionName, 'Call already disconnected, returning interaction..', this.interactionsList);
        const earlyInteraction: any = await this.callAlreadyInState(call, INTERACTION_STATES.Disconnected);
        return earlyInteraction;
      }
      this.log(LOG_LEVEL.Trace, functionName, 'Calling ClickToAct() to HANGUP call..', call);
      this.promisesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.hangup,
        scenarioId: noInteractions ? null : call.interactions[call.interactions.length - 1].scenarioId,
        interactionId: noInteractions ? null : call.interactions[call.interactions.length - 1].interactionId,
        deferredPromise: new DeferredPromise(),
        eventData: call
      });
      this.log(LOG_LEVEL.Trace, functionName, 'Added to promises to resolve', this.promisesToResolve);
      clickToAct(
        noInteractions ? null : call.interactions[call.interactions.length - 1].details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.Hangup,
        noInteractions ? null : call.interactions[call.interactions.length - 1].interactionId,
        call
      );
      setTimeout(() => this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.hangup, functionName, call), this.scs.eventTimeout);
      this.failedHangupAttempts = 0;
      return this.promisesToResolve[this.promisesToResolve.length - 1].deferredPromise.promise;
    } catch (error) {
      if (this.failedHangupAttempts > 1) {
        if (call.interactions.length > 0 && Object.keys(this.interactionsList).length === 0) {
          // if there are no calls in the interactions list simply end the call as no call is being tracked
          this.log(LOG_LEVEL.Warning, functionName, 'No call being tracked, ending call', call);
          this.failedHangupAttempts = 0;
          return Promise.resolve(call.interactions[call.interactions.length - 1]);
        }
      }
      this.failedHangupAttempts++;
      this.log(LOG_LEVEL.Error, functionName, 'Failed to hangup call', { failedHangupAttempts: this.failedHangupAttempts, error });
    }
  }

  @bind
  async muteCallEvent(call: Call) {
    const functionName = 'muteCallEvent';
    try {
      // TODO test with CTI that supports Mute
      this.log(LOG_LEVEL.Debug, functionName, 'Calling ClickToAct() to MUTE call..', call);
      this.promisesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.muted,
        scenarioId: call.interactions[call.interactions.length - 1].scenarioId,
        interactionId: call.interactions[call.interactions.length - 1].interactionId,
        deferredPromise: new DeferredPromise(),
        eventData: call
      });
      this.log(LOG_LEVEL.Trace, functionName, 'Added to promises to resolve', this.promisesToResolve);
      clickToAct(
        call.interactions[call.interactions.length - 1].details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.Mute,
        call.interactions[call.interactions.length - 1].interactionId,
        call
      );
      setTimeout(() => this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.muted, functionName, call), this.scs.eventTimeout);
      return this.promisesToResolve[this.promisesToResolve.length - 1].deferredPromise.promise;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to mute call', error);
    }
  }

  @bind
  async unmuteCallEvent(call: Call) {
    const functionName = 'unmuteCallEvent';
    try {
      // TODO test with CTI that supports Mute
      this.log(LOG_LEVEL.Debug, functionName, 'Calling ClickToAct() to UNMUTE call..', call);
      this.promisesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.unmuted,
        scenarioId: call.interactions[call.interactions.length - 1].scenarioId,
        interactionId: call.interactions[call.interactions.length - 1].interactionId,
        deferredPromise: new DeferredPromise(),
        eventData: call
      });
      this.log(LOG_LEVEL.Trace, functionName, 'Added to promises to resolve', this.promisesToResolve);
      clickToAct(
        call.interactions[call.interactions.length - 1].details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.Unmute,
        call.interactions[call.interactions.length - 1].interactionId,
        call
      );
      setTimeout(() => this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.unmuted, functionName, call), this.scs.eventTimeout);
      return this.promisesToResolve[this.promisesToResolve.length - 1].deferredPromise.promise;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to unmute call', error);
    }
  }

  // Compares SalesForce Call object with records in DPG. Returns false if
  // the DaVinci interaction object is not already in the given state, and
  // returns the interaction if it is already in the given state. This is
  // for convenience as the function can be used for true/false control flow
  // and also to retrieve the interaction that must be returned to SalesForce.
  async callAlreadyInState(call: Call, state: INTERACTION_STATES, recurse = true): Promise<boolean | IInteraction> {
    const functionName = 'callAlreadyInState';
    try {
      // If the call from SalesForce has the fields we require..
      if (call?.interactions?.length > 0) {
          this.log(LOG_LEVEL.Trace, functionName, 'Comparing Call', { call, state });

        // Call can sometimes have undefined elements in its interactions array, filter them out.
        const validInteractions = call.interactions.filter(interaction => Boolean(interaction));
        // Return false if there are no valid interactions to check with
        if (validInteractions.length < 1) {
          this.log(LOG_LEVEL.Warning, functionName, 'Call has no valid interactions to check with');
          return false;
        }
        this.log(LOG_LEVEL.Trace, functionName, 'Call has valid interaction(s)', validInteractions);
        // If the call were are checking for is DISCONNECTED, check for it as an expired Interaction.
        // If it's not there then we know we haven't received DISCONNECTED yet
        const interactionList = state === INTERACTION_STATES.Disconnected ? this.expiredInteractionsList : this.interactionsList;


        // Special handling for a disconnected event
        // If any of the nested conditions fail, it is okay to continue through
        // this function normally.
        if (state === INTERACTION_STATES.Disconnected) {
          if (!this.expiredInteractionsList || !(this.expiredInteractionsList.get(validInteractions[validInteractions.length - 1].interactionId))) {
            // We don't have an 'already-disconnected' interaction.
            // If we don't have an active interaction either, we know something broke
            if (!this.interactionsList || !(this.interactionsList.get(validInteractions[validInteractions.length - 1].interactionId))) {
              // Since we have no interaction to return, just return a dummy interaction with state set to DISCONNECTED.
              this.log(LOG_LEVEL.Trace, functionName, 'No interactions to return', call);
              const retInteraction = validInteractions[validInteractions.length - 1];
              retInteraction.state = INTERACTION_STATES.Disconnected;
              return retInteraction;
            }
          }
        }


        if (!interactionList || interactionList.size < 1) {
          // No interactions to compare against, return false
          this.log(LOG_LEVEL.Trace, functionName, 'No interactions to compare against', interactionList);
          return false;
        }

        // Return Interaction if already in the given state, or false otherwise.
        const ret = interactionList.get(validInteractions[validInteractions.length - 1].interactionId).state
          === state ? interactionList.get(validInteractions[validInteractions.length - 1].interactionId) : false;
        return ret;
      }

      // Call did not contain what we required, see if it is in localStorage of Bridge
      const activeCalls = this.state.activeCalls;
      if (
        call &&
        call['callId'] &&
        activeCalls &&
        activeCalls[call.callId]
      ) {
        // If the call in localStorage had what we needed, try again.]
        this.log(LOG_LEVEL.Loop, functionName, 'Call in local storage contains required information, Recursing..', { call, state });
        return recurse ? this.callAlreadyInState(activeCalls[call.callId], state, false) : false;
      }
      this.log(LOG_LEVEL.Trace, functionName, 'No conditions pass, return false');
      return false;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to check if call is already in state', error);
    }
  }

  @bind
  async holdCallEvent(call: Call) {
    const functionName = 'holdCallEvent';
    try {
      this.log(LOG_LEVEL.Debug, functionName, 'Hold Call Event', call);

      let noInteractions = false;
      // If the call does not have the necessary data, attempt recovery
      if (!call['interactions'] || call.interactions.length < 1 || !call.interactions[call.interactions.length - 1]) {
        this.log(LOG_LEVEL.Warning, functionName, 'Call has no interactions. Attempting to recover.');
        const storedCalls = this.state.activeCalls;
        // No hope if stored call has no interactions either
        if (!storedCalls[call.callId] ||
          !storedCalls[call.callId]['interactions'] ||
          storedCalls[call.callId].interactions.length < 1) {
            this.log(LOG_LEVEL.Warning, functionName, 'Could not recall interactions from localStorage');
            noInteractions = true;
        } else {
          // Reapply the stored interactions onto the Call object
          this.log(LOG_LEVEL.Trace, functionName, 'Reapplying stored interactions onto call object', this.interactionsList);
          call['interactions'] = storedCalls[call.callId].interactions;
        }

      }

      // Remove null and undefined values if present
      const validInteractions = noInteractions ? null : call['interactions']
        .filter(interaction => interaction && interaction['interactionId']);
      if (await this.callAlreadyInState(call, INTERACTION_STATES.OnHold, null)) {
        // Call already held, return the interaction
        this.log(LOG_LEVEL.Trace, functionName, 'Call already held, returning interaction.');
        const earlyInteraction: any = await this.callAlreadyInState(call, INTERACTION_STATES.OnHold);
        return earlyInteraction;
      }

      this.log(LOG_LEVEL.Trace, functionName, 'Calling ClickToAct() to HOLD call..', call);
      this.promisesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.held,
        scenarioId: noInteractions ? null : validInteractions[validInteractions.length - 1].scenarioId,
        interactionId: noInteractions ? null : validInteractions[validInteractions.length - 1].interactionId,
        deferredPromise: new DeferredPromise(),
        eventData: call
      });
      this.log(LOG_LEVEL.Trace, functionName, 'Added to promises to resolve', this.promisesToResolve);
      clickToAct(
        noInteractions ? null : validInteractions[validInteractions.length - 1].details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.Hold,
        noInteractions ? null : validInteractions[validInteractions.length - 1].interactionId,
        call
      );
      setTimeout(() => this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.held, functionName, call), this.scs.eventTimeout);
      return this.promisesToResolve[this.promisesToResolve.length - 1].deferredPromise.promise;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to hold call', error);
    }
  }

  @bind
  async unholdCallEvent(call: Call) {
    const functionName = 'unholdCallEvent';
    try {
      this.log(LOG_LEVEL.Debug, functionName, 'Unhold Call Event', call);

      let noInteractions = false;

      // Attempt recovery after loss of data
      if (!call['interactions'] || call.interactions.length < 1 || !call.interactions[call.interactions.length - 1]) {
        this.log(LOG_LEVEL.Warning, functionName, 'Call has no interactions. Attempting to recover.');
        const storedCalls = this.state.activeCalls;
        // No hope if stored call has no interactions either
        if (!storedCalls[call.callId] ||
          !storedCalls[call.callId]['interactions'] ||
          storedCalls[call.callId].interactions.length < 1) {
            this.log(LOG_LEVEL.Warning, functionName, 'Could not recall interactions from localStorage');
          noInteractions = true;
        } else {
          call['interactions'] = storedCalls[call.callId].interactions;
          this.log(LOG_LEVEL.Trace, functionName, 'Reapplying stored interactions onto call object', this.interactionsList);
        }
      }

      const validInteractions = noInteractions ? null : call['interactions'].filter(interaction => interaction && interaction['interactionId']);

      this.log(LOG_LEVEL.Trace, functionName, 'Calling ClickToAct() to HOLD call..', call);
      this.promisesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.unheld,
        scenarioId: noInteractions ? null : validInteractions[validInteractions.length - 1].scenarioId,
        interactionId: noInteractions ? null : validInteractions[validInteractions.length - 1].interactionId,
        deferredPromise: new DeferredPromise(),
        eventData: call
      });
      this.log(LOG_LEVEL.Trace, functionName, 'Added to promises to resolve', this.promisesToResolve);
      clickToAct(
        noInteractions ? null : validInteractions[validInteractions.length - 1].details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.Unhold,
        noInteractions ? null : validInteractions[validInteractions.length - 1].interactionId,
        call
      );
      setTimeout(() => this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.unheld, functionName, call), this.scs.eventTimeout);
      return this.promisesToResolve[this.promisesToResolve.length - 1].deferredPromise.promise;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to unhold call', error);
    }
  }

  @bind
  async addParticipant(participant: { call: Call, contact: { phoneNumber: string } }) {
    const functionName = 'addParticipant';
    try {
      // Todo: implement PhoneNumber Formatting here
      this.log(LOG_LEVEL.Debug, functionName, 'Calling ClickToAct() to ADDPARTICIPANT call..', participant);
      this.promisesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.addParticipant,
        scenarioId: participant.call.interactions[participant.call.interactions.length - 1].scenarioId,
        interactionId: '',
        deferredPromise: new DeferredPromise(),
        eventData: participant.contact.phoneNumber
      });
      this.log(LOG_LEVEL.Trace, functionName, 'Added to promises to resolve', this.promisesToResolve);
      clickToAct(
        participant.contact.phoneNumber,
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.AddParticipant,
        participant.call.interactions[participant.call.interactions.length - 1].interactionId,
        participant.call
      );
      setTimeout(() => this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.addParticipant, functionName, participant), this.scs.eventTimeout);
      return this.promisesToResolve[this.promisesToResolve.length - 1].deferredPromise.promise;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to add participant', error);
      return Promise.reject('Failed to add participant');
    }
  }

  @bind
  async blindTransfer(blindTransfer: { call: Call, contact: { phoneNumber: string }}) {
    const functionName = 'blindTransfer';
    try {
      // TODO: implement PhoneNumber Formatting here
      this.log(LOG_LEVEL.Debug, functionName, 'Calling ClickToAct() to BLINDTRANSFER call..', blindTransfer);
      // TODO: Send transfer to Salesforce Via communicationService after successful transfer

      this.promisesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.blindTransferred,
        scenarioId: blindTransfer.call.interactions[blindTransfer.call.interactions.length - 1].scenarioId,
        interactionId: blindTransfer.call.interactions[blindTransfer.call.interactions.length - 1].interactionId,
        deferredPromise: new DeferredPromise(),
        eventData: blindTransfer.contact.phoneNumber
      });
      this.log(LOG_LEVEL.Trace, functionName, 'Added to promises to resolve', this.promisesToResolve);
      clickToAct(
        blindTransfer.contact.phoneNumber,
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.BlindTransfer,
        blindTransfer.call.interactions[blindTransfer.call.interactions.length - 1].interactionId,
        null
      );
      setTimeout(() => this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.blindTransferred, functionName, blindTransfer.contact.phoneNumber), this.scs.eventTimeout);
      return this.promisesToResolve[this.promisesToResolve.length - 1].deferredPromise.promise;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to blind transfer', error);
      return Promise.reject('Failed to perform blind transfer');
    }
  }

  @bind
  async warmTransfer(call: Call) {
    const functionName = 'warmTransfer';
    try {
      // Todo: implement PhoneNumber Formatting here
      this.log(LOG_LEVEL.Debug, functionName, 'Calling ClickToAct() to WARMTRANSFER call..', call);
      this.promisesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.warmTransferred,
        scenarioId: call.interactions[call.interactions.length - 1].scenarioId,
        interactionId: call.interactions[call.interactions.length - 1].interactionId,
        deferredPromise: new DeferredPromise(),
        eventData: call
      });
      this.log(LOG_LEVEL.Trace, functionName, 'Added to promises to resolve', this.promisesToResolve);
      clickToAct(
        call.interactions[call.interactions.length - 1].details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.WarmTransfer,
        call.interactions[call.interactions.length - 1].interactionId,
        call
      );
      setTimeout(() => this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.warmTransferred, functionName, call), this.scs.eventTimeout);
      return this.promisesToResolve[this.promisesToResolve.length - 1].deferredPromise.promise;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to warm transfer', error);
      return Promise.reject('Failed to add participant');
    }
  }

  @bind
  async conference(calls: Call[]) {
    const functionName = 'conference';
    try {
      // Todo: implement PhoneNumber Formatting here
      this.log(LOG_LEVEL.Debug, functionName, 'Calling ClickToAct() to conference call..', calls);

      const call = calls[1];
      this.promisesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.conference,
        scenarioId: call.interactions[call.interactions.length - 1].scenarioId,
        interactionId: call.interactions[call.interactions.length - 1].interactionId,
        deferredPromise: new DeferredPromise(),
        eventData: call
      });
      this.log(LOG_LEVEL.Trace, functionName, 'Added to promises to resolve', this.promisesToResolve);
      clickToAct(
        call.interactions[call.interactions.length - 1].details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.Conference,
        call.interactions[call.interactions.length - 1].interactionId,
        call
      );
      setTimeout(() => this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.conference, functionName, call), this.scs.eventTimeout);
      return this.promisesToResolve[this.promisesToResolve.length - 1].deferredPromise.promise;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to conference', error);
      return Promise.reject('Failed to add participant');
    }
  }

  @bind
  async removeParticipant(call: Call) {
    const functionName = 'removeParticipant';
    try {
      const partiesObject = JSON.parse(call.interactions[call.interactions.length - 1].details.fields['conferenceParties']['Value'].replace(/\\"/g, '"'));
      const conferenceKeys = Object.keys(partiesObject);
      let participantIndex = '0';
      for (let i = 0; i < conferenceKeys.length; i++) {
        this.log(LOG_LEVEL.Loop, functionName, 'Checking conference key', conferenceKeys[i]);
        if (partiesObject[conferenceKeys[i]] === call.phoneNumber) {
          this.log(LOG_LEVEL.Loop, functionName, 'Found participant index', [i]);
          participantIndex = i.toString();
          break;
        }
      }
      // Todo: implement PhoneNumber Formatting here
      this.log(LOG_LEVEL.Debug, functionName, 'Calling ClickToAct() to REMOVEPARTICIPANT call..', call);
      this.promisesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.removeParticipant,
        scenarioId: call.interactions[call.interactions.length - 1].scenarioId,
        interactionId: call.interactions[call.interactions.length - 1].interactionId,
        deferredPromise: new DeferredPromise(),
        eventData: call
      });
      this.log(LOG_LEVEL.Trace, functionName, 'Added to promises to resolve', this.promisesToResolve);
      clickToAct(
        call.phoneNumber,
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.RemoveParticipant,
        call.interactions[call.interactions.length - 1].interactionId,
        participantIndex
      );
      setTimeout(() => this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.removeParticipant, functionName, call), this.scs.eventTimeout);
      return this.promisesToResolve[this.promisesToResolve.length - 1].deferredPromise.promise;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to remove participant', error);
      return Promise.reject('Failed to add participant');
    }
  }

  @bind
  async sendOutbound(phoneNumber) {
    const functionName = 'sendOutbound';
    try {
      this.log(LOG_LEVEL.Debug, functionName, 'Outbound Event', phoneNumber);
      // Todo: Implement PhoneNumber Formatting

      this.promisesToResolve.push({
        eventType: PROMISE_EVENT_TYPE.outbound,
        scenarioId: '',
        interactionId: '',
        deferredPromise: new DeferredPromise(),
        eventData: phoneNumber
      });
      this.log(LOG_LEVEL.Trace, functionName, 'Added to promises to resolve', this.promisesToResolve);

      clickToDial(phoneNumber);

      setTimeout(() => this.rejectPromiseTimeout(PROMISE_EVENT_TYPE.outbound, functionName, phoneNumber), this.scs.eventTimeout);
      return this.promisesToResolve[this.promisesToResolve.length - 1].deferredPromise.promise;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to send outbound call', error);
    }
  }

  @bind
  async sendDigits(digits) {
    const functionName = 'sendDigits';
    // Todo: this needs to be discussed as to how this will be handled in each CTI that supports this
    try {
      return clickToAct(digits, null, null, CONTEXTUAL_OPERATION_TYPE.DTMF);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to send digits', error);
    }
  }

  // Stores the start time and Salesforce callId of the supplied interaction in storage service.
  // This is later used for calculating and updated call duration in Salesforce.
  @bind
  async setOutboundCallId(callDetails: { interactionId: string, startTime: Date, voiceCallId: string }) {
    const functionName = 'setOutboundCallId';

    try {
      this.log(LOG_LEVEL.Debug, functionName, 'Setting Outbound Call Id', callDetails);

      this.storageService.interactionToVoiceCallRecordDetailsMap.set(callDetails.interactionId, {
        voiceCallId: callDetails.voiceCallId,
        startTime: callDetails.startTime
      });

      this.communicationService.registerUCIDAndAgentId(callDetails.voiceCallId);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to set outbound call id', error);
    }
  }


  /*
   * Logging, Logic, and cleanup functions for the component
   */
  @bind
  logFromBridge(logObject: {
    logLevel: LOG_LEVEL,
    functionName: string,
    message: string,
    object?: any
  }) {
    const functionName = 'logFromBridge';
    try {
      this.log(logObject.logLevel, logObject.functionName, logObject.message, logObject.object);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to log from bridge', { logObject, error });
    }
  }

  protected removeLocalStorageOnLogout(reason?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        localStorage.clear();
        resolve('Success');
      } catch (error) {
        reject(error);
      }
    });
  }

  protected formatPhoneNumber(
    inputNumber: string,
    phoneNumberFormat: Object
  ): string {
    const functionName = 'FormatPhoneNumber';
    try {
      this.log(LOG_LEVEL.Trace, functionName, 'Formatting Phone Number', { inputNumber, phoneNumberFormat });
      const configuredInputFormats = Object.keys(phoneNumberFormat);
      for (let index = 0; index < configuredInputFormats.length; index++) {
        let formatCheck = true;
        const inputFormat = configuredInputFormats[index];
        const outputFormat = phoneNumberFormat[inputFormat];
        if (inputFormat.length === inputNumber.length) {
          const arrInputDigits = [];
          let outputNumber = '';
          let outputIncrement = 0;
          if (
            (inputFormat.match(/x/g) || []).length !==
            (outputFormat.match(/x/g) || []).length
          ) {
            continue;
          }
          for (let j = 0; j < inputFormat.length; j++) {
            if (inputFormat[j] === 'x') {
              arrInputDigits.push(j);
            } else if (
              inputFormat[j] !== '?' &&
              inputNumber[j] !== inputFormat[j]
            ) {
              formatCheck = false;
              break;
            }
          }
          if (formatCheck) {
            for (let j = 0; j < outputFormat.length; j++) {
              if (outputFormat[j] === 'x') {
                outputNumber =
                  outputNumber + inputNumber[arrInputDigits[outputIncrement]];
                outputIncrement++;
              } else {
                outputNumber = outputNumber + outputFormat[j];
              }
            }
            this.log(LOG_LEVEL.Trace, functionName, 'Formatted Phone Number', outputNumber);
            return outputNumber;
          }
        }
      }
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Failed to format phone number', {inputNumber, phoneNumberFormat, error});
    } finally {
      this.log(LOG_LEVEL.Warning, functionName, 'Number unable to be formatted, returning input.', {inputNumber, phoneNumberFormat});
      return inputNumber;
    }
  }

  protected clickToDialFormatPhoneNumber(number: any) {
    const configuredInputFormats = Object.keys(
      this.scs.clickToDialPhoneReformatMap
    );
    for (let i = 0; i < configuredInputFormats.length; i++) {
      let formatCheck = true;
      if (number.length === configuredInputFormats[i].length) {
        // Length of incoming number matches length of a configured input format
        // Now Validate # of X's in input/output
        const inputFormat = configuredInputFormats[i];
        const outputFormat =
          this.scs.clickToDialPhoneReformatMap[configuredInputFormats[i]];
        const arrInputDigits = [];
        let outputNumber = '';
        let outputIncrement = 0;
        if (
          (inputFormat.match(/x/g) || []).length !==
          (outputFormat.match(/x/g) || []).length
        ) {
          continue;
        }
        if (
          (inputFormat.match(/\(/g) || []).length !==
          (number.match(/\(/g) || []).length
        ) {
          continue;
        }
        if (
          (inputFormat.match(/-/g) || []).length !==
          (number.match(/-/g) || []).length
        ) {
          continue;
        }

        for (let j = 0; j < inputFormat.length; j++) {
          if (inputFormat[j] === 'x') {
            arrInputDigits.push(j);
          } else if (inputFormat[j] !== '?' && number[j] !== inputFormat[j]) {
            formatCheck = false;
            break;
          }
        }
        if (formatCheck) {
          for (let k = 0; k < outputFormat.length; k++) {
            if (outputFormat[k] === 'x') {
              outputNumber =
                outputNumber + number[arrInputDigits[outputIncrement]];
              outputIncrement++;
            } else {
              outputNumber = outputNumber + outputFormat[k];
            }
          }
          return outputNumber;
        }
      }
    }
    return number;
  }

  /*
   * Default DaVinci functions used to control softphone settings for a CRM
   * Not currently implemented
   */
  protected isToolbarVisible(): Promise<boolean> {
    return this.bridgeEventsService.sendEvent('isToolbarVisible');
  }

  protected saveActivity(activity: IActivity): Promise<string> {
    throw new Error('Method not implemented.');
  }

  protected getSearchLayout(): Promise<SearchLayouts> {
    throw new Error('Method not implemented.');
  }

  protected formatCrmResults(crmResults: any): SearchRecords {
    throw new Error('Method not implemented.');
  }
}
